import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute,
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'gate', EN);
i18next.addResourceBundle('lt', 'gate', LT);

export const GateListRoute = getExactRoute('gate', 'menu.list', '/gate/:parking', lazy(() => import('./layout/GateListLayout')), null, ['admin']);
export const GateEditRoute = getHiddenRoute('gate', 'menu.list', '/gate/:parking/:id', lazy(() => import('./layout/GateEditLayout')), null, ['admin']);
export const GateCreateRoute = getHiddenRoute('gate', 'menu.list', '/gate/:parking/new', lazy(() => import('./layout/GateEditLayout')), null, ['admin']);


const GateRoute = (
  <>
    {GateListRoute}
    {GateEditRoute}
    {GateCreateRoute}
  </>
);

export default GateRoute;
