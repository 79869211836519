import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'profit', EN);
i18next.addResourceBundle('lt', 'profit', LT);

export const ProfitMainRoute = getExactRoute('profit', 'menu.main', '/profit', lazy(() => import('./layout/ProfitLayout')), null, ['admin']);

const ProfitRoute = (
  <>
    {ProfitMainRoute}
  </>
);

export default ProfitRoute;
