import React from 'react';
import {Route} from 'react-router-dom';
import NotFoundLayout from './layout/NotFoundLayout';
import DeniedLayout from 'layout/DeniedLayout';
import DashboardFeature from 'feature/dashboard/routes';
import LoginFeature from 'feature/login/routes';
import ParkingFeature from 'feature/parking/routes'
import CompanyFeature from 'feature/company/routes'
import OrderFeature from 'feature/order/routes'
import InvoiceFeature from 'feature/invoice/routes'
import CarFeature from 'feature/cars/routes';
import GateFeature from 'feature/gate/routes';
import UserRoute from "./feature/user/routes";
import OrdererRoute from "./feature/orderer/routes";
import HistoryRoute from "./feature/parking-history/routes";
import SubscriptionsRoute from "./feature/subscriptions/routes";
import OneTimeRoute from "./feature/one-time-reservations/routes";
import InfractionsRoute from "./feature/infractions/routes";
import SettingsRoute from "./feature/settings/routes";
import {ProfitMainRoute} from "./feature/profit/routes";

const Routes = (
  <>
    {LoginFeature}
    {DashboardFeature}
    {ProfitMainRoute}
    {SettingsRoute}
    {CompanyFeature}
    {OrderFeature}
    {InvoiceFeature}
    {ParkingFeature}
    {CarFeature}
    {UserRoute}
    {OrdererRoute}
    {InfractionsRoute}
    {HistoryRoute}
    {GateFeature}
    {OneTimeRoute}
    {SubscriptionsRoute}
    <Route isHidden path='/denied' element={<DeniedLayout />} />
    <Route isHidden path='*' element={<NotFoundLayout />} />
  </>
);

export default Routes;
