import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute,
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'order', EN);
i18next.addResourceBundle('lt', 'order', LT);

export const OrderListRoute = getExactRoute('order', 'menu.list', '/order', lazy(() => import('./layout/OrderListLayout')), null, ['admin']);
export const OrderEditRoute = getHiddenRoute('order', 'menu.list', '/order/:id', lazy(() => import('./layout/OrderEditLayout')), null, ['admin']);
export const OrderCreateRoute = getHiddenRoute('order', 'menu.list', '/order/new', lazy(() => import('./layout/OrderEditLayout')), null, ['admin']);


const OrderRoute = (
  <>
    {OrderListRoute}
    {OrderEditRoute}
    {OrderCreateRoute}
  </>
);

export default OrderRoute;
