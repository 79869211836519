import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'dashboard', EN);
i18next.addResourceBundle('lt', 'dashboard', LT);

export const DashboardMainRoute = getExactRoute('dashboard', 'menu.main', '/', lazy(() => import('./layout/DashboardLayout')), null, ['admin', 'inspector']);

const DashboardRoute = (
  <>
    {DashboardMainRoute}
  </>
);

export default DashboardRoute;
