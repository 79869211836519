import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute,
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'one-time', EN);
i18next.addResourceBundle('lt', 'one-time', LT);

export const OneTimeListRoute = getExactRoute('one-time', 'menu.list', '/one-time/:parking', lazy(() => import('./layout/OneTimeListLayout')), null, ['admin']);


const OneTimeRoute = (
  <>
    {OneTimeListRoute}
  </>
);

export default OneTimeRoute;
