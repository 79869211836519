import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute,
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'subscriptions', EN);
i18next.addResourceBundle('lt', 'subscriptions', LT);

export const SubscriptionsListRoute = getExactRoute('subscriptions', 'menu.list', '/subscriptions/:parking', lazy(() => import('./layout/SubscriptionsListLayout')), null, ['admin']);


const SubscriptionsRoute = (
  <>
    {SubscriptionsListRoute}
  </>
);

export default SubscriptionsRoute;
