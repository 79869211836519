import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute,
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'invoice', EN);
i18next.addResourceBundle('lt', 'invoice', LT);

export const InvoiceListRoute = getExactRoute('invoice', 'menu.list', '/invoice', lazy(() => import('./layout/InvoiceListLayout')), null, ['admin', 'accountant']);
export const InvoiceEditRoute = getHiddenRoute('invoice', 'menu.list', '/invoice/:id', lazy(() => import('./layout/InvoiceEditLayout')), null, ['admin', 'accountant']);
export const InvoiceCreateRoute = getHiddenRoute('invoice', 'menu.list', '/invoice/new', lazy(() => import('./layout/InvoiceEditLayout')), null, ['admin', 'accountant']);


const InvoiceRoute = (
  <>
    {InvoiceListRoute}
    {InvoiceEditRoute}
    {InvoiceCreateRoute}
  </>
);

export default InvoiceRoute;
