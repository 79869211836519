import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute,
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'infractions', EN);
i18next.addResourceBundle('lt', 'infractions', LT);

export const InspectionRoute = getExactRoute('inspection', 'Inspection', '/inspection', lazy(() => import('./layout/Inspection')), null, ['admin', 'inspector']);
export const InfractionsListRoute = getExactRoute('infractions', 'menu.list', '/infractions', lazy(() => import('./layout/InfractionsListLayout')), null, ['admin', 'inspector']);
export const InfractionsCreateRoute = getHiddenRoute('infractions', 'menu.list', '/infractions/new', lazy(() => import('./layout/InfractionsEditLayout')), null, ['admin', 'inspector']);


const InfractionsRoute = (
  <>
    {InspectionRoute}
    {InfractionsListRoute}
    {InfractionsCreateRoute}
  </>
);

export default InfractionsRoute;
