import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute,
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'history', EN);
i18next.addResourceBundle('lt', 'history', LT);

export const HistoryListRoute = getExactRoute('history', 'menu.list', '/history/:parking', lazy(() => import('./layout/HistoryListLayout')), null, ['admin']);


const HistoryRoute = (
  <>
    {HistoryListRoute}
  </>
);

export default HistoryRoute;
