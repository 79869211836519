import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute,
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'parking', EN);
i18next.addResourceBundle('lt', 'parking', LT);

export const ParkingListRoute = getExactRoute('parking', 'menu.list', '/parking', lazy(() => import('./layout/ParkingListLayout')), null, ['admin']);
export const ParkingEditRoute = getHiddenRoute('parking', 'menu.list', '/parking/:id', lazy(() => import('./layout/ParkingEditLayout')), null, ['admin']);
export const ParkingCreateRoute = getHiddenRoute('parking', 'menu.list', '/parking/new', lazy(() => import('./layout/ParkingEditLayout')), null, ['admin']);

const ParkingRoute = (
  <>
    {ParkingListRoute}
    {ParkingEditRoute}
    {ParkingCreateRoute}
  </>
);

export default ParkingRoute;
