import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute,
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'orderer', EN);
i18next.addResourceBundle('lt', 'orderer', LT);

export const OrdererListRoute = getExactRoute('orderer', 'menu.list', '/orderer', lazy(() => import('./layout/OrdererListLayout')), null, ['admin']);
export const OrdererEditRoute = getHiddenRoute('orderer', 'menu.list', '/orderer/:id', lazy(() => import('./layout/OrdererEditLayout')), null, ['admin']);
export const OrdererCreateRoute = getHiddenRoute('orderer', 'menu.list', '/orderer/new', lazy(() => import('./layout/OrdererEditLayout')), null, ['admin']);


const OrdererRoute = (
  <>
    {OrdererListRoute}
    {OrdererEditRoute}
    {OrdererCreateRoute}
  </>
);

export default OrdererRoute;
