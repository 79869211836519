import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'settings', EN);
i18next.addResourceBundle('lt', 'settings', LT);

export const SettingsMainRoute = getExactRoute('settings', 'menu.main', '/settings', lazy(() => import('./layout/SettingsLayout')), null, ['admin']);

const SettingsRoute = (
  <>
    {SettingsMainRoute}
  </>
);

export default SettingsRoute;
