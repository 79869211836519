import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute,
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'company', EN);
i18next.addResourceBundle('lt', 'company', LT);

export const CompanyListRoute = getExactRoute('company', 'menu.list', '/company', lazy(() => import('./layout/CompanyListLayout')), null, ['admin']);
export const CompanyEditRoute = getHiddenRoute('company', 'menu.list', '/company/:id', lazy(() => import('./layout/CompanyEditLayout')), null, ['admin']);
export const CompanyCreateRoute = getHiddenRoute('company', 'menu.list', '/company/new', lazy(() => import('./layout/CompanyEditLayout')), null, ['admin']);


const CompanyRoute = (
  <>
    {CompanyListRoute}
    {CompanyEditRoute}
    {CompanyCreateRoute}
  </>
);

export default CompanyRoute;
