import {lazy} from 'react';
import i18next from 'i18next';

import {
  getExactRoute,
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json'

i18next.addResourceBundle('en', 'car', EN);
i18next.addResourceBundle('lt', 'car', LT);

export const CarListRoute = getExactRoute('car', 'menu.list', '/car', lazy(() => import('./layout/CarListLayout')), null, ['admin']);
export const CarEditRoute = getHiddenRoute('car', 'menu.list', '/car/:id', lazy(() => import('./layout/CarEditLayout')), null, ['admin']);
export const CarCreateRoute = getHiddenRoute('car', 'menu.list', '/car/new', lazy(() => import('./layout/CarEditLayout')), null, ['admin']);


const CarRoute = (
  <>
    {CarListRoute}
    {CarEditRoute}
    {CarCreateRoute}
  </>
);

export default CarRoute;
